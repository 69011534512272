<script lang="ts">
    import { onMount } from 'svelte';
    import { fly } from 'svelte/transition';
    import { spring } from 'svelte/motion';
    import { inView, animate } from 'motion';

    import Header from '@/template/header.svelte';
    import Footer from '@/template/footer.svelte';
    import SuggestionCard from './SuggestionCard.svelte';
    import Button from '$lib/components/ui/button/button.svelte';

    import { Icon, ArrowDownTray, Sparkles } from 'svelte-hero-icons';
    import { UserCheck, Percent, CandlestickChart, DollarSign } from 'lucide-svelte';

    export let authUser;
    export let suggestions;
    export let page_description;
    export let share_url;
    export let share_title;
    export let share_description;
    export let share_type;
    export let share_img;

    let activeTab: string = 'creators';
    let previousTab: string = '';
    let tabsContainer: HTMLElement;
    let borderSpring: any;

    const tabs = [
        { id: 'creators', label: 'For Creators' },
        { id: 'supporters', label: 'For Supporters' },
        { id: 'managers', label: 'For Managers' },
    ];

    function setActiveTab(tab: string) {
        if (tab !== activeTab) {
            previousTab = activeTab;
            activeTab = tab;
            updateBorderSpring();
        }
    }

    function updateBorderSpring() {
        const activeElement = tabsContainer.querySelector(`[data-tab="${activeTab}"]`);
        if (activeElement) {
            borderSpring.set({
                left: activeElement.offsetLeft,
                width: activeElement.offsetWidth,
            });
        }
    }

    function getTransitionDirection(newTab: string) {
        const tabsOrder = ['creators', 'supporters', 'managers'];
        const newIndex = tabsOrder.indexOf(newTab);
        const oldIndex = tabsOrder.indexOf(previousTab);

        return newIndex < oldIndex ? 90 : -90;
    }

    onMount(() => {
        borderSpring = spring(
            { left: 0, width: 0 },
            {
                stiffness: 1,
                damping: 1,
            }
        );
        updateBorderSpring();

        inView('section', ({ target }) => {
            animate(
                target.querySelectorAll('.contents'),
                { opacity: 1, transform: 'none' },
                { delay: 0.2, duration: 1.0, easing: [0.17, 0.55, 0.55, 1] }
            );
        });
        inView('section', ({ target }) => {
            animate(
                target.querySelectorAll('.hero-contents'),
                { opacity: 1, transform: 'none' },
                { delay: 0.2, duration: 1.0, easing: [0.17, 0.55, 0.55, 1] }
            );
        });
    });
</script>

<svelte:head>
    <title>{share_title}</title>
    <meta name="description" content="{page_description}" />
    <meta property="og:url" content="{share_url}" />
    <meta property="og:title" content="{share_title}" />
    <meta property="og:description" content="{share_description}" />
    <meta property="og:type" content="{share_type}" />
    <meta property="og:image" content="{share_img}" />
    <script type="application/ld+json">
      {`
        {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "${share_title}",
          "url": "${share_url}",
          "address": ""
        }
      `}
    </script>
    <link rel="stylesheet" href="/css/pages/home.css" />
    <link rel="stylesheet" href="/css/pages/search.css" />
</svelte:head>

<Header {authUser} />
<main>
    <!-- Hero -->
    <section>
        <main class="">
            <div class="relative isolate overflow-x-clip overflow-y-visible">
                <svg
                    class="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)] dark:stroke-gray-200/10"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                            width="200"
                            height="200"
                            x="50%"
                            y="-1"
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M.5 200V.5H200" fill="none"></path>
                        </pattern>
                    </defs>
                    <svg x="50%" y="-1" class="overflow-visible fill-gray-50 dark:fill-gray-300/10">
                        <path
                            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                            stroke-width="0"
                        ></path>
                    </svg>
                    <rect width="100%" height="100%" stroke-width="0" fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
                    ></rect>
                </svg>
                <div
                    class="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-visible blur-3xl lg:ml-24 xl:ml-48"
                    aria-hidden="true"
                >
                    <div
                        class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
                        style="clip-path: polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)"
                    ></div>
                </div>
                <div class="hero-contents overflow-hidden">
                    <div class="mx-auto max-w-7xl px-6 pb-32 pt-0 sm:pt-60 lg:px-8 lg:pt-32">
                        <div class="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                            <div class="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                                <h1
                                    class="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-200 sm:text-6xl"
                                >
                                    Exclusive Content Redefined
                                </h1>
                                <p
                                    class="relative mt-6 text-lg leading-8 text-gray-600 dark:text-gray-200 sm:max-w-md lg:max-w-none"
                                >
                                    BuyMyNudes gives you the tools you need to make the most out of your content, whilst
                                    providing the best experience for your fans.
                                </p>
                                <div class="mt-10 flex items-center gap-x-2">
                                    <Button href="/register" class="text-white">Sign Up</Button>
                                    <Button href="#" variant="ghost" class=""
                                        >Learn more <span class="ml-2" aria-hidden="true">→</span></Button
                                    >
                                </div>
                            </div>
                            <div
                                class="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0"
                            >
                                <div
                                    class="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80"
                                >
                                    <div class="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                                            alt=""
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        ></div>
                                    </div>
                                </div>
                                <div class="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                                    <div class="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                                            alt=""
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        ></div>
                                    </div>
                                    <div class="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80"
                                            alt=""
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        ></div>
                                    </div>
                                </div>
                                <div class="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                                    <div class="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1670272504528-790c24957dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80"
                                            alt=""
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        ></div>
                                    </div>
                                    <div class="relative">
                                        <img
                                            src="https://images.unsplash.com/photo-1670272505284-8faba1c31f7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                                            alt=""
                                            class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                        />
                                        <div
                                            class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </section>

    <!-- Features -->
    <section class="!mt-8">
        <div class="mx-auto contents max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl lg:text-center">
                <h2 class="text-base font-semibold leading-7 text-indigo-600">Simply the best</h2>
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-200 sm:text-4xl">
                    Why we're different
                </p>
                <p class="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-200">
                    We're community driven, cutting edge and have all involved parties in mind.
                </p>
            </div>
            <div>
                <div class="w-fixed px-30 relative mt-5 flex flex-row justify-around" bind:this="{tabsContainer}">
                    {#each tabs as tab}
                        <div
                            class="cursor-pointer pb-2 dark:text-gray-200"
                            class:selected="{activeTab === tab.id}"
                            on:click="{() => setActiveTab(tab.id)}"
                            data-tab="{tab.id}"
                        >
                            {tab.label}
                        </div>
                    {/each}
                    {#if borderSpring}
                        <div
                            class="absolute bottom-0 h-0.5 bg-bmn-500 transition-all duration-300"
                            style="left: {$borderSpring.left}px; width: {$borderSpring.width}px;"
                        ></div>
                    {/if}
                </div>
                <div class="content-container min-h-[30rem]">
                    {#if activeTab === 'creators'}
                        <div
                            in:fly="{{ x: getTransitionDirection('creators'), duration: 500 }}"
                            class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-16 lg:max-w-4xl"
                        >
                            <dl
                                class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16"
                            >
                                <div class="relative pl-16">
                                    <dt class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
                                        <div
                                            class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="h-6 w-6 text-white"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
                                                ></path>
                                            </svg>
                                        </div>
                                        Powerful overall
                                    </dt>
                                    <dd class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-200">
                                        Proper analytics, innovative ways to monetize, highly flexible content handling,
                                        dedicated creator support, and the list goes on.
                                    </dd>
                                </div>
                                <div class="relative pl-16">
                                    <dt class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
                                        <div
                                            class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600"
                                        >
                                            <svg
                                                class="h-6 w-6 text-white"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                                ></path>
                                            </svg>
                                        </div>
                                        Securing your content
                                    </dt>
                                    <dd class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-200">
                                        We encrypt all of your media with DRM-Grade technology by default, plus imprint
                                        media digitally with sensitive data of the viewer.
                                    </dd>
                                </div>
                                <div class="relative pl-16">
                                    <dt class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
                                        <div
                                            class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600"
                                        >
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M12 6C13.1046 6 14 5.10457 14 4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4C10 5.10457 10.8954 6 12 6Z"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M5 21C6.10457 21 7 20.1046 7 19C7 17.8954 6.10457 17 5 17C3.89543 17 3 17.8954 3 19C3 20.1046 3.89543 21 5 21Z"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M12 21C13.1046 21 14 20.1046 14 19C14 17.8954 13.1046 17 12 17C10.8954 17 10 17.8954 10 19C10 20.1046 10.8954 21 12 21Z"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M19 21C20.1046 21 21 20.1046 21 19C21 17.8954 20.1046 17 19 17C17.8954 17 17 17.8954 17 19C17 20.1046 17.8954 21 19 21Z"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M12 6C9 10.1481 5 8.7037 5 17"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M12 6C15 10.1481 19 8.7037 19 17"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                                <path
                                                    d="M12 6V17"
                                                    stroke="white"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                ></path>
                                            </svg>
                                        </div>
                                        Deep Referral System
                                    </dt>
                                    <dd class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-200">
                                        Our referral system tracks multiple levels of referrals at a referral split of
                                        20%, ensuring you can effectively monetize your outside reach.
                                    </dd>
                                </div>
                                <div class="relative pl-16">
                                    <dt class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
                                        <div
                                            class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600"
                                        >
                                            <svg
                                                class="h-6 w-6 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="currentColor"
                                            >
                                                <path
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
                                                ></path>
                                            </svg>
                                        </div>
                                        Visibility
                                    </dt>
                                    <dd class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-200">
                                        We believe that it's essential to grant small creators visibility, hence we
                                        built several features making it easier for new creators to gain traction on our
                                        platform, as well as on external platforms.
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    {/if}
                    {#if activeTab === 'supporters'}
                        <div
                            in:fly="{{ x: getTransitionDirection('supporters'), duration: 500 }}"
                            class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-16 lg:max-w-4xl"
                        >
                            <dl
                                class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16"
                            >
                                <div class="relative pl-16">
                                    <dt class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
                                        <div
                                            class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600"
                                        >
                                            <Icon src="{Sparkles}" class="size-6 text-white" />
                                        </div>
                                        High Quality Content
                                    </dt>
                                    <dd class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-200">
                                        We know that you want to experience your content to the fullest. That's why we
                                        went all in on high-bitrate codecs for video and audio as well as near lossless
                                        image encoding.
                                    </dd>
                                </div>
                                <div class="relative pl-16">
                                    <dt class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
                                        <div
                                            class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600"
                                        >
                                            <Icon src="{ArrowDownTray}" class="size-6 text-white" />
                                        </div>
                                        Downloads
                                    </dt>
                                    <dd class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-200">
                                        We allow creators to enable downloads on their media, allowing you to download
                                        their content in full quality onto your disk.
                                    </dd>
                                </div>
                                <div class="relative pl-16">
                                    <dt class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
                                        <div
                                            class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600"
                                        >
                                            <UserCheck class="text-white" />
                                        </div>
                                        No AIs allowed
                                    </dt>
                                    <dd class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-200">
                                        In the age of AI it becomes increasingly difficult to differentiate between
                                        humans and AI. To combat that, our chat system is designed to sustain attempts
                                        of using AI to chat. Sorry Sam.
                                    </dd>
                                </div>
                                <div class="relative pl-16">
                                    <dt class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
                                        <div
                                            class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600"
                                        >
                                            <Percent class="text-white" />
                                        </div>
                                        Ownership
                                    </dt>
                                    <dd class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-200">
                                        Freely trade and auction creators, as well as limited edition posts. Bid on new
                                        users who signed up without referral code or buy/bid onto listed creators on the
                                        market - the 20% referral share you get access to whilst owning accounts can
                                        make it quite lucrative.
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    {/if}
                    {#if activeTab === 'managers'}
                        <div
                            in:fly="{{ x: getTransitionDirection('managers'), duration: 500 }}"
                            class="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-16 lg:max-w-4xl"
                        >
                            <dl
                                class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16"
                            >
                                <div class="relative pl-16">
                                    <dt class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
                                        <div
                                            class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600"
                                        >
                                            <CandlestickChart class="text-white" />
                                        </div>
                                        Creators as assets
                                    </dt>
                                    <dd class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-200">
                                        Sell your creators on the market - or expand your agency and hunt on the market
                                        to buy the next Amouranth for cheap to potentially make a fortune.
                                    </dd>
                                </div>
                                <div class="relative pl-16">
                                    <dt class="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
                                        <div
                                            class="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600"
                                        >
                                            <DollarSign class="text-white" />
                                        </div>
                                        Locked in split
                                    </dt>
                                    <dd class="mt-2 text-base leading-7 text-gray-600 dark:text-gray-200">
                                        No matter what's the case, your 20% of your creators revenue will persist.
                                        Creators can only create one profile.
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    {/if}
                </div>
            </div>
        </div>
    </section>

    <!-- Creators -->
    <section class="">
        <div class="mx-auto my-5 contents max-w-7xl px-6 py-5 lg:px-8">
            <div>
                <div class="mb-4 text-center">
                    <h2 class="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-200">Newest Creators</h2>
                    <p class="text-gray-700 dark:text-gray-200">
                        Here are some creators who just signed up. Get access now to their content, direct messages &
                        more!
                    </p>
                </div>
            </div>
            <div>
                {#if suggestions.length > 0}
                    <div class="grid gap-4 md:grid-cols-4">
                        {#each suggestions as profile}
                            <SuggestionCard {profile} isListMode="{false}" isListManageable="{false}" />
                        {/each}
                    </div>
                {:else}
                    <div>Oops, a bit unusual but there are no suggested members at this time.</div>
                {/if}
            </div>
        </div>
    </section>

    <!-- Questions  -->
    <div class="white-section my-4 py-4">
        <div>
            <div class="text-center">
                <h3 class="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-200">Questions?</h3>
                <p class="dark:text-gray-200">
                    Don't hesitate to send us a message via our -
                    <Button href="/contact" variant="link" class="text-md p-0">Contact Form</Button>.
                </p>
            </div>
        </div>
    </div>
</main>
<Footer />

<style>
    /* Styles */
    section .contents {
        display: block;
        transform: translateX(-100px);
        opacity: 0;
    }

    section .hero-contents {
        display: block;
        transform: translateY(100px);
        opacity: 0;
    }
</style>
